import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {
    DIRECT_SIGN_PATH,
    NEW_DIRECT_SIGN_PATH,
    SIGN_PATH
} from '../../../constants';

function isSignRelatedUrl(url: string): boolean {
    const paths = [SIGN_PATH, DIRECT_SIGN_PATH];
    return paths.some((path) => {
        const regex = new RegExp(`${path}(\\/|\\?|$)`);
        return regex.test(url);
    });
}

export function getNewUrl(pathname: string, search: string): string | null {
    if (isSignRelatedUrl(pathname + search)) {
        let newPath = pathname.replace(SIGN_PATH, NEW_DIRECT_SIGN_PATH).replace(DIRECT_SIGN_PATH, NEW_DIRECT_SIGN_PATH);
        newPath = newPath.replace(/\/{2,}/g, '/'); // Remove double slashes
        return `${newPath}${search}`;
    }
    return null;
}

/**
 * Component to redirect from old /sign url to new /ceremony
 */
const RedirectSignToCeremony: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        const newUrl = getNewUrl(location.pathname, location.search);
        if (newUrl) {
            window.location.href = newUrl;
        }
    }, [location]);

    return null;
};

export {RedirectSignToCeremony};
